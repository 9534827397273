import React from 'react';

const Cookies = () => (
    <div>
        <div className="overlay" style={{ backgroundImage: `url('images/05.jpg')`, opacity: 0.25, backgroundRepeat: 'no-repeat' }}></div>
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-lg-3">
                    </div>
                    <div className="col-xs-12 col-lg-9">
                        <h2>Evästekäytäntö</h2>
                        <p>
                            Käyttämällä sivustoa hyväksyt, että Bomat Oy voi asettaa evästeitä tietokoneellesi tai mobiililaitteeseesi. Ilmoitamme muutoksistamme evästeiden käytön suhteen tällä sivulla.
                        </p>
                        <h4>Mitä evästeet ovat?</h4>
                        <p>
                            Evästeet ovat pieniä palvelimen muodostamia tekstitiedostoja, jotka internetselain tallentaa käyttäjän laitteelle selaimen käyttämien tiedostojen yhteyteen.
                            Evästeitä käyttö on tarpeen esimerkiksi silloin, kun käyttäjän tietoja halutaan säilyttää siirtyessä internetpalvelun sivulta toiselle. Evästeitä on sekä väliaikaisia (poistuvat päätelaitteeltasi, kun suljet selaimen) että pysyviä (säilyvät pidemmän, ennalta määritellyn ajan).
                        </p>
                        <p>
                            Lisätietoa evästeistä saat esimerkiksi Viestintäviraston internetsivuilta.
                        </p>

                        <h4>Mihin evästeitä käytetään?</h4>
                        <p>
                            Evästeet tekevät Bomat Oy:n sivustojen käyttämisestä helpompaa, nopeampaa ja tehokkaampaa. Niiden myötä käyttäjäkokemus voidaan hioa paremmaksi.
                        </p>
                        <p>
                            Evästeet tunnistavat laitteesi, kun saavut Bomat Oy:n www-sivustolle uudelleen. Lisäksi evästeet voivat tallentaa sivustolla aikaisemmin tekemiäsi valintoja ja asetuksia. Evästeiden avulla pystytään myös mukauttamaan käyttäjäkohtaista sisältöä sivustolla.
                        </p>

                        <h4>Mitä evästeitä käytetään?</h4>
                        <p>
                            Bomat Oy:n www-sivustolla saatamme käyttää sekä väliaikaisia että pysyviä evästeitä. Jotkin evästeet ovat sivuston teknisen toiminnan ja käytön kannalta välttämättömiä.
                            Saatamme myös käyttää suorituskykyä mittaavia ja toimintaa seuraavia evästeitä.
                        </p>
                        <p>
                            <b>Toiminnallisten evästeiden</b> avulla verkkosivumme ylläpitää selainistuntoja ja muistaa käyttäjän valintoja ja asetuksia kuten käyttökielen. Niiden avulla sivusto voi myös tarjota yksilöllisiä ominaisuuksia ja paremman käyttökokemuksen. Voit kytkeä toiminnalliset evästeet pois käytöstä selaimesi asetuksista. Huomaathan, että tällöin sivusto ei toimi toivotulla tavalla.
                        </p>
                        <p>
                            <b>Suorituskykyä mittaavat</b> evästeet keräävät tietoa siitä, miten käyttäjät käyttävät sivustoamme (esim. eniten käytetyt sivut, mahdolliset virheviestit). Tämän kaltaiset evästeet ovat anonyymejä ja niitä käytetään ainoastaan nettisivujen toimivuuden parantamiseksi. Ne eivät siis kerää käyttäjistä tunnistettavia tietoja.
                        </p>
                        <p>
                            Sivuilla olevat kolmansien osapuolten liitännäispalvelut kuten mainokset, Google-tilastointi, YouTube-videot tai Facebook-liitännäiset, saattavat tallentaa käyttäjän tunnistamiseen käytettävää tietoa. Lisäksi sivustollamme saattaa olla myös painikkeita, jotka helpottavat sisällön jakamista eri viestintäkanaviin kuten sosiaaliseen mediaan. Näiden evästeiden keräämä tieto saattaa siirtyä ja tallentua toimijoiden palvelimille, jotka voivat sijaita EU:n ulkopuolella. Voit kytkeä kolmannen osapuolen evästeet pois käytöstä selaimesi asetuksista. Tämä saattaa kuitenkin johtaa siihen, että osa sivustosta ei toimi toivotulla tavalla.
                        </p>
                        <p>
                            Sivusto käyttää Google Analytics –evästeitä. Google Analyticsin avulla keräämme tietoa siitä, miten sivustoa käytetään. Se myös auttaa Bomat Oy:t kehittämään www-sivuston käytön käyttäjäkokemusta. Google Analyticsin keräämät tiedot siirretään ja tallennetaan Googlen palvelimille, jotka saattavat sijaita myös EU:n ulkopuolella. Palvelimien sijaitessa EU:n ulkopuolella sovelletaan paikallista tietosuojalainsäädäntöä.
                        </p>
                        <p>
                            Lisätietoja Googlen yksityisyyskäytännöistä:<br />
                            <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>
                        </p>
                        <p>
                            Google Analyticsin käytön voi estää osoitteessa:<br />
                            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                        </p>

                        <h4>Evästeiden hallinta ja estäminen</h4>
                        <p>
                            Halutessasi voit muuttaa Internet-selaimesi asetuksia niin, että saat ilmoituksen aina kun evästeitä ollaan lähettämässä tietokoneellesi. Voit myös estää evästeiden käytön kokonaan. Katso tarkemmat ohjeet selaimesi verkkosivuilta.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const CookiesPage = {
    routeProps: {
        path: '/evastekaytanto',
        Component: Cookies
    },
    name: 'Evästekäytäntö',
}


export default CookiesPage;

