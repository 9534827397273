import React from 'react';

const Contact = () => (
    <div>
    <div className="overlay" style={{ backgroundImage: `url('images/05.jpg')`, opacity: 0.25, backgroundRepeat: 'no-repeat' }}></div>
    <div className="content">
        <div className="container">
            <div className="row">
            <div className="hidden-xs col-lg-3">
            </div>
            <div className="col-xs-12 col-lg-9">
                    
                    <h3>Sijainti ja toimitukset</h3>
                    <p className='mb-4'>Toimitilat sijaitsevat keskeisellä paikalla Helsingin Tukkutorilla.</p>
                    <p className='mb-4'>Pääkaupunkiseudulla toimitamme päivittäin tuotteet itse sopimuksen mukaisesti suoraan asiakkaillemme. Kaukokohteiden toimituksesta vastaa yhteistyökumppanimme LTP Logistics.</p> 
                    <div>
                    <iframe title="Kartta" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.3814453754276!2d24.973434476576713!3d60.19097004175555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bd416e100c5%3A0x980b991498210003!2sBomat%20Oy!5e0!3m2!1sfi!2sfi!4v1706357735881!5m2!1sfi!2sfi" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
);

const ContactPage = {
    routeProps: {
        path: '/sijainti',
        Component: Contact
    },
    name: 'Sijainti',
}


export default ContactPage;

