import React, { useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useCookies } from "react-cookie";

import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
registerLocale('fi', fi)


const Customers = () => {

  const [showModal, setShowModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleDescriptionClose = () => setShowDescriptionModal(false);

  const [selectedId, setSelectedId] = useState(false);
  const [selectedName, setSelectedName] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [selectedKey, setSelectedKey] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);


  const openRequestModal = (row) => {
    setSelectedId(row.product_code)
    setSelectedName(row.product_desc)
    setSelectedAmount(row.product_amount);
    setSelectedTime(row.delivery_time)
    setSelectedDate(row.delivery_date)
    setSelectedKey(row.key)
    setShowModal(true);
  }

  const openDescriptionModal = (row) => {
    getData(row.product_code, row.product_desc);
  }

  var backend_url = "https://crm.bomat.fi";

  if (window.location.protocol === "file:" || window.location.hostname === "localhost") {
    backend_url = "http://localhost:3000";
  }

  var customerNumberRef = useRef();
  var amountRef = useRef();


  var [orderdata, setOrderdata] = useState([]);
  var [msg, setMsg] = useState([]);
  var [descriptionModalTitle, setDescriptionModalTitle] = useState([]);
  var [amountErrorMsg, setAmountErrorMsg] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  var [datepickerVisibility, setDatepickerVisibility] = useState('d-none');
  var [html_rows, setHtml_rows] = useState([]);

  const handleSetSelectedView = (view) => {
    setMsg('');
    setDatepickerVisibility("d-none");
    setOrderdata({});

    if (view === "open") {
      getOrders(null);
    }
    if (view === "old") {
      setMsg('Valitse haluamasi päivä kalenterista nähdäksesi aikaisempia tilauksia');
      setDatepickerVisibility("d-inline");
    }
  }

  const handleDateSelect = (date) => {
    getOrders(date);
  }
  
  const selectUserBtn = () => {
    setDatepickerVisibility("d-none");
    getOrders(null);
  }

  const getOrders = (date) => {

    setMsg('');
    setOrderdata({});

    var requestUser = "";

    if (cookies.user) {
      requestUser = cookies.user;
    } else {
      requestUser = customerNumberRef.current.value;
    }
    
    if (requestUser !== "") {
      var params = {
        uid: requestUser
      };

      if (date !== null) {
        params.date = date
      }

      fetch(backend_url + "/front/getinvoicesbycompany?" + new URLSearchParams(params))
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error) {
              setMsg(result.msg);
                setCookie("user", '', { path: "/" });
              setOrderdata({});
            }
            else {
              if (!cookies.user) {
                setCookie("user", customerNumberRef.current.value, { path: "/" });
              }

              if (Object.keys(result).length < 1) {
                setMsg("Ei avoimia tilauksia.");
              }
              else {
                setOrderdata(result);
              }
              
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        )
    }
  }

  const getData = (id, name) => {

    // TODO: muuta url
    fetch(backend_url + "/json/getitemsfront?" + new URLSearchParams({ id: id }))
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error) {
              alert ("virhe: " + result)
            }
            else {
              if (Object.keys(result).length > 0) {
                checkChildren([result], 0, function( html_rows ) {
                  setDescriptionModalTitle(id + " " + name );
                  setHtml_rows(html_rows);
                  setShowDescriptionModal(true);
                });
              }
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        )
  }
  const printData = () => {
      window.print();
  }

  const sendRequest = () => { 

    // eslint-disable-next-line
    if (amountRef.current.value === "" || amountRef.current.value == null || amountRef.current.value == selectedAmount ) {
      setAmountErrorMsg('Tarkasta lukumäärä');
    }
    else {
      setAmountErrorMsg('');
      var headers = new Headers();
      headers.append('Accept', 'application/json'); 
      headers.append('Content-Type', 'application/x-www-form-urlencoded'); 

      fetch(backend_url + "/front/invoice_request", {
        method: 'POST',
        headers: headers,
        body: new URLSearchParams({
          amount: amountRef.current.value, 
          invoice_id: selectedKey,
          username: cookies.user
        })

      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error){
              if (result.data === 'pending') {
                alert("Tuotteelle on tehty jo muutospyyntö. Odota että aiempi pyyntö käsitellään tai soita meille numeroon 010 235 8800*");
              }
              else if (result.data === 'amount_error') {
                alert("Ole hyvä ja tarkista syöttämäsi tuotteen määrä.");
              }
              else if (result.data === 'date_error') {
                alert("Tilausta ei voi valitettavasti enää muuttaa. Muutospyyntö olisi pitänyt jättää " + result.info + " mennessä. \n\nMikäli on tapahtunut virhe tai kyseessä on erikoistapaus voit soitaa meille numeroon 010 235 8800*.");
              }
              else {
                alert("Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*");
              }
            } else {
              getOrders(null);
              setShowModal(false);
            }
          },
          (error) => {
            console.log("Virhe: ", error);
            alert("Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*");
            setShowModal(false);
          }
        )
    }
  }

  const clearCookie = () => {
    setCookie("user", '', { path: "/" });
    setOrderdata({});
    // window.location.reload(false);
  }
  
  const checkChildren = (items, level=0, callback) => {

    var html = "";
    var upperCaseWords = [];

      // Tässä voi olla annoskortti tai raaka-aine
      for (const [id, product] of Object.entries(items)) {
        console.log("id: ", id);
        for (const [id, data] of Object.entries(product)) {
          console.log("id: ", id);
          // Näytetään vain ulkoiset kortit, koska vain niissä voi olla tuoteseloste
          if (data.inhouse_produced === 0){
            if (!data.content) {
              // Raaka-aineen tietoja ei löydy
              data.content = "<i class='small'>Raaka-ainetiedot puuttuvat.</i>";
            }
            else {
              // Etsitään kaikki isolla kirjoitetut sanat
              upperCaseWords = data.content.match(/(\b[A-ZÄÅÖ][A-ZÄÖ]+|\b[A-ZÄÅÖ]\b)/g);

              if (upperCaseWords) {
                
                // Siivotaan pois useammin kuin kerran esiintyvät sanat
                let uniqueUpperCaseWords = [...new Set(upperCaseWords)];
    
                // Käydään läpi kaikki isolla kirjoitetut sanat
                for (var j = 0; j < uniqueUpperCaseWords.length; j++) {
                  
                  // Lihavoidaan vain 3 merkkiä pidemmät sanat
                  if (uniqueUpperCaseWords[j].length > 3) {
                    data.content = data.content.replaceAll(uniqueUpperCaseWords[j], "<b>" + uniqueUpperCaseWords[j] + "</b>");
                  }	
                }
              }
            }
    
            html += "<div class='row ms-2'><div class='col-12'><b>";
    
            let tuotenimi = ucfirst(data.name);
    
            // Etsitään " 100g " tyyppistä merkkijonoa
            var splitter = new RegExp(' ([0-9][0-9]{0,3})g ').exec(data.name);
    
            // Jos löytyy paino, katkaistaan nimi siitä.
            if (splitter) {
               var parts = data.name.split(splitter[0]);
               tuotenimi = ucfirst(parts[0]);
            }
    
            if (data.common_name != null) {
              tuotenimi = ucfirst(data.common_name);
            }
    
            html += tuotenimi + "</b></div></div>";
            
            html += "<div class='row mb-1 ms-2'><div class='col-12'>"
            // Vain annoskorteissa näytetään raaka-ainetiedot
            if (!data.commodity) { html += data.content }
            html += "</div></div>";	
                      
          }
          else {
            // tarkistetaan halutaanko "välitaso" näyttää
            if (data.visible) {
              html += "<div class='row ms-2'><div class='col-12'>";
              html += "<b>" + ucfirst(data.name) + "</b>";
          
            }
          }
    
          // Haetaan mahdolliset ali-reseptit
          if (data.children) {
            // eslint-disable-next-line 
            checkChildren(data.children, level+1, function(sub_html) {
              html += sub_html;
            });
          }
          // Lisätään välitason lopetus kun lapset on listattu
          if (data.visible) {
            html += "</div></div>";	
          }
        }
      }
      callback(html);
  };
    
  const ucfirst = (string) => {
	  return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (

    <div>
     <div className="overlay" style={{ backgroundImage: `url('images/02.jpg')`, opacity: 0.25, zIndex: 0, backgroundRepeat: 'no-repeat' }}></div>
      <div className='d-none d-sm-none d-md-block mt-50'></div>
      <div className="content">
        <div className="container d-print-none mb-5">
          <div className="row">
            <div className="col-md-3 col-xs-12 mb-4">
              {!cookies.user && (
                <div>
                  <label htmlFor="customer_number">Asiakastunnus</label> 
                  <input type="text" id="customer_number" name="customer_number" ref={customerNumberRef}></input>
                  <button type="button" className="button" onClick={selectUserBtn}>Hae</button>
                </div>
              )}
              <b>{cookies.user }</b>
              {cookies.user && (
                <ul>
                  <li onClick={() => handleSetSelectedView('open')}>Avoimet tilaukset </li>
                  <li onClick={() => handleSetSelectedView('old')}>Aiemmat tilaukset </li>
                  <div className={datepickerVisibility}>
                    <DatePicker inline locale="fi" selected={startDate} onChange={(date) => setStartDate(date)}  onSelect={handleDateSelect} />
                  </div>
                  <li onClick={clearCookie}>Kirjaudu ulos</li>
                </ul>
              )}
            </div>
            <div className="col-md-9 col-xs-12">

              <Accordion defaultActiveKey={0}>
                {Object.keys(orderdata).map((company_name, i) => (
                  <Accordion.Item eventKey={i} key={i}>
                    <Accordion.Header><h2 className='mb-0'>{company_name}</h2></Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(orderdata[company_name]).map((date, j) => (
                        <div className="card mb-1" key={company_name + "-" + date + "-" + j}>
                          <div className="card-header" aria-expanded="true">
                            <label className="mb-0">Toimituspäivä: {date}</label>
                          </div>
                          <div className="card-body">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th style={{width: '15%'}}>Tuotekoodi</th>
                                  <th style={{width: '50%'}}>Nimi</th>
                                  <th style={{width: '10%'}}>Lkm</th>
                                  <th style={{width: '35%'}}>Tila</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(orderdata[company_name][date]).map((row, k) => (
                                  <tr key={company_name + "-" + date + "-" + k}>
                                    <td>{orderdata[company_name][date][row].product_code}</td>
                                    <td style={{ cursor: "pointer" }} onClick={() => openDescriptionModal(orderdata[company_name][date][row])}>{orderdata[company_name][date][row].product_desc}</td>
                                    {orderdata[company_name][date][row].pending !== "Odottaa käsittelyä" && <td style={{ cursor: "pointer" }} onClick={() => openRequestModal(orderdata[company_name][date][row])}>{orderdata[company_name][date][row].product_amount} kpl</td>}
                                    {orderdata[company_name][date][row].pending === "Odottaa käsittelyä" && <td>{orderdata[company_name][date][row].product_amount} kpl</td>}
                                    <td>{orderdata[company_name][date][row].pending}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}

                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              {Object.keys(orderdata).length < 1 && (
                <div>
                  <div>{msg}</div>
                  {!cookies.user && (
                    <div id="orders">Syötä vasemmalla olevaan kenttään asiakasnumero ja paina "hae" nähdäksesi avoimet tilaukset</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title">Tee tilaukseen muutospyyntö</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Tuote: {selectedName}<br />
              Tuotenumero: {selectedId}<br />
              Toimitusaika: {selectedDate}, kello {selectedTime}<br />
              Alkuperäinen määrä: {selectedAmount} kpl
            </p>
            <div>
              <div id="new_amount_input">
                Syötä toivomasi uusi määrä: <input type="number" max="999" min="0" defaultValue={selectedAmount} ref={ amountRef } /><span className="text-danger">{amountErrorMsg}</span>
              </div>
              <div id="new_amount_disabled" style={{ display: "none" }}>
                Tilausta ei voi enää muuttaa.
              </div>
              <input type="hidden" name="invoice_id" id="invoice_id" /><br />
              <input type="hidden" name="username" id="username" /><br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={sendRequest} >Lähetä</Button>
            <Button variant="light" onClick={handleClose} >Peruuta</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDescriptionModal} onHide={handleDescriptionClose} dialogClassName="descriptionModal" 
        backdropClassName='d-print-none'
        contentClassName='w-100'
        className='w-100'>

          <Modal.Header closeButton className="d-print-none">
            <Modal.Title><h5 className="modal-title">{descriptionModalTitle}</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <h5 className="modal-title d-print-block d-none mb-4">{descriptionModalTitle}</h5>
                <div
                      dangerouslySetInnerHTML={{
                        __html: html_rows,
                      }}
                    ></div>
          </Modal.Body>
          <Modal.Footer className="d-print-none">
            <Button variant="warning" onClick={printData} >Tulosta</Button>
            <Button variant="light" onClick={handleDescriptionClose} >Peruuta</Button>
          </Modal.Footer>
        </Modal>
    </div>
  )
};

const CustomersPage = {
  routeProps: {
      path: '/asiakkaille',
      Component: Customers
  },
  name: 'Asiakkaille',
}

export default CustomersPage;