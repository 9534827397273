import React from 'react';
import ModalImage from "react-modal-image";

const Products = () => (
  <div>
    <div className="overlay" style={{ backgroundImage: `url('images/04.jpg')`, opacity: 0.25, backgroundRepeat: 'no-repeat' }}></div>
    <div className="content">


      <div className="container">
        <div className="row">
          <div className="hidden-xs col-lg-3">
          </div>
          <div className="col-xs-12 col-lg-9">
            <h3>Tuotteet ja toimitus</h3>
            <p className='mb-4'>Valmistamme kuukaudessa keskimäärin 40 000 tuotetta ja tuotannossamme on tällä hetkellä yli 1000 erilaista täytettyä leipä vaihtoehtoa. Käytössämme on useiden leipomoiden leipiä, jotka esikäsittelemme ja täytämme asiakkaan toiveiden mukaan. Emme lisää tuotteisiimme säilöntäaineita, emmekä pakkaa niitä kaasuun.</p>
            <p className='mb-4'>Suuremmat toimitukset jaamme tarvittaessa eri päiville, jotta tuotteet olisivat aina mahdollisimman tuoreita. Täytetty tuote voidaan tarvittaessa pakata yksittäin, grillitaskuun tai sellaisenaan kuljetuslaatikkoon. Tuotteitamme voi tilata laskutuksella.</p>
            <p className='mb-4'>Sopimusasiakkaamme voivat tarkistaa ajantasaiset tuoteselosteet suoraan nettisivuiltamme.</p>
            <p className='mb-4'>Alla muutamia esimerkkejä asiakkaillemme valmistamistamme tuotteista</p>
          </div>
        </div>
        <div className='row'>
        <div className="hidden-xs col-lg-3"></div>
        <div className='col-9'>
          <ModalImage small='images/02.jpg' large='images/02.jpg' className='lightboxIcon' alt="Wrap"/>
          <ModalImage small='images/05.jpg' large='images/05.jpg' className='lightboxIcon' alt="Salami sandwich"/>
          <ModalImage small='images/04.jpg' large='images/04.jpg' className='lightboxIcon' alt="Mozzarella sämpylä"/>
          <ModalImage small='images/03.jpg' large='images/03.jpg' className='lightboxIcon' alt="Briejuusto patonki"/>
          <ModalImage small='images/01.jpg' large='images/01.jpg' className='lightboxIcon' alt="Juusto ruisleipä"/>
          </div>
        </div>
        <div className='row'>
        <div className="hidden-xs col-lg-3"></div>
        <div className='col-9'>
          <ModalImage small='images/11.jpg' large='images/11.jpg' className='lightboxIcon' alt="Paahtopaisti ruisnappi"/>
          <ModalImage small='images/12.jpg' large='images/12.jpg' className='lightboxIcon' alt="Kinkku-juusto patonki"/>
          <ModalImage small='images/13.jpg' large='images/13.jpg' className='lightboxIcon' alt="Graavilohi patonki"/>
          <ModalImage small='images/14.jpg' large='images/14.jpg' className='lightboxIcon' alt="Emmental sämpylä"/>
          <ModalImage small='images/15.jpg' large='images/15.jpg' className='lightboxIcon' alt="Kinkku-juusto sämpylä"/>
          </div>
        </div>
      </div>

    </div>
  </div>
);

const productsPage = {
  routeProps: {
    path: '/tuotteet',
    Component: Products
  },
  name: 'Tuotteet',
};

export default productsPage;