import Front from './front'; 
import Contact from './contact';
import Products from './products'; 
import Customers from './customers'
import Video from './video'
import Cookies from './cookies'

const index = [
    Front,
    Contact,
    Products,
    Customers,
    Video,
    Cookies,
];

export default index;