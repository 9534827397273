import React from 'react';

const Front = () => (

    <div>
        <div className="overlay" style={{ backgroundImage: `url('images/bomat_slogan.png')`, opacity: 0.25, backgroundRepeat: 'no-repeat' }}></div>
        <div className='d-none d-sm-none d-md-block mt-100'></div>
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-lg-3">
                    </div>
                    <div className="col-xs-12 col-lg-9">
                        <div>
                            <p className='mb-4'>Bomat Oy on tuore-elintarvikkeita valmistava yritys. Toimintamme keskiössä on laadukkaiden täytettyjen leipätuotteiden valmistus. Valmistamme myös muita tuotteita, kuten wrappeja ja salaatteja, riippuen asiakkaan toiveista.</p>
                            <p className='mb-4'>Tuoreiden ja mahdollisuuksien mukaan kotimaisten raaka-aineiden avulla pyrimme varmistamaan, että tuotteemme ovat aina raikkaita ja maistuvia.</p>
                            <h3>Toiminta ja asiakkaat</h3>
                            <p className='mb-4'>Yhtiön toiminta alkoi vuonna 1993, jolloin yhtiö valmisti juustosämpylöitä ja kolmioleipiä kaupoille ja ravintoloille. Nykyään Bomat valmistaa leipätuotteita laajalla valikoimalla Suomen johtaville hotelli- ja ravintolaketjuille. Asiakkaisiimme kuuluuvat myös pääkaupunkiseudun suuret tapahtumajärjestäjät ja toimitamme tuotteita myös mm. varustamoille.</p>
                            <p className='mb-4'>Räätälöimme aina käsintehdyt tuotteemme asiakaskohtaisesti tilaajan toiveiden mukaan niin pienempiin yritystilaisuuksiin kuin tuhansien henkien massatapahtumiin.</p>
                            <p className='mb-4'>Olemme mukana <a href="https://www.vastuugroup.fi/fi-fi/palvelut/luotettava-kumppani">Suomen Tilajavastuu.fi Oy:n</a> Luotettava Kumppani -ohjelmassa. <a href="https://zeckit.com/selvitys/FI/0944172-6?section=quickcheck" rel="noreferrer" target="_blank">Luotettava Kumppani</a> -jäsenyys kertoo, että yritys hoitaa asiat avoimesti ja yrityksen lakisääteiset velvoitteet ovat ajan tasalla.</p> 
                            
         
                            <p className='mb-4'>Bomat on myös Suomen yrittäjien ja Helsingin kauppakamarin jäsenyritys.</p>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const frontPage = {
    routeProps: {
        path: '/',
        Component: Front
    },
    name: 'Yrityksestä',
};


export default frontPage;